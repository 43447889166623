import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Content from "../../Content";
import SideBar from "../../SideBar";
import NavigationTab from "../../SideBar/NavigationTab";
import HomeSvg from "../../../themes/assets/Home";
import CashSvg from "../../../themes/assets/Cash";
import ClaimsSvg from "../../../themes/assets/Claims";

function SideBarAndContent() {
    const location = useLocation();

    return (
        <>
            <SideBar>
                {location.pathname.includes("/plaid_item/") ? null : (
                    <>
                        <NavigationTab path="/" iconSrc={HomeSvg}>
                            Overview
                        </NavigationTab>
                        <NavigationTab path="/cash" iconSrc={CashSvg}>
                            Cash
                        </NavigationTab>
                        <NavigationTab path="/claims" iconSrc={ClaimsSvg}>
                            Claims
                        </NavigationTab>
                    </>
                )}
            </SideBar>
            <Content>
                <Outlet />
            </Content>
        </>
    );
}

export default SideBarAndContent;

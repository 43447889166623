import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer } from "recharts";
import getChartData from "./utils";
import CustomTooltip from "./Tooltip";

function PayerRevenueChart({ payerData, chartHeight }) {
    const chartData = useMemo(() => getChartData(payerData), [payerData]);

    const [payer, setPayer] = useState(undefined);

    const onMouseLeave = () => {
        setPayer(undefined);
    };

    return (
        <ResponsiveContainer width="100%" height={chartHeight}>
            <BarChart data={chartData}>
                <XAxis dataKey="month" />
                <Tooltip content={<CustomTooltip payer={payer} />} />
                <Bar
                    dataKey="NEVADA MEDICAID"
                    stackId="a"
                    fill="#59B87D"
                    stroke="#59B87D"
                    fillOpacity={0.5}
                    onMouseOver={() => setPayer("NEVADA MEDICAID")}
                    onMouseLeave={onMouseLeave}
                />
                <Bar
                    dataKey="AETNA"
                    stackId="a"
                    fill="#8884D8"
                    stroke="#8884D8"
                    fillOpacity={0.5}
                    onMouseOver={() => setPayer("AETNA")}
                    onMouseLeave={onMouseLeave}
                />
                <Bar
                    dataKey="UNITED MEDICAL RESOURCES"
                    stackId="a"
                    fill="#43291F"
                    stroke="#43291F"
                    fillOpacity={0.5}
                    onMouseOver={() => setPayer("UNITED MEDICAL RESOURCES")}
                    onMouseLeave={onMouseLeave}
                />
                <Bar
                    dataKey="NEVADA BLUE SHIELD"
                    stackId="a"
                    fill="#7E007B"
                    stroke="#7E007B"
                    fillOpacity={0.5}
                    onMouseOver={() => setPayer("NEVADA BLUE SHIELD")}
                    onMouseLeave={onMouseLeave}
                />
                <Bar
                    dataKey="UNITED HEALTHCARE"
                    stackId="a"
                    fill="#DFBE99"
                    stroke="#DFBE99"
                    fillOpacity={0.5}
                    onMouseOver={() => setPayer("UNITED HEALTHCARE")}
                    onMouseLeave={onMouseLeave}
                />
                <Bar
                    dataKey="CIGNA SELECT (FORMERLY GREAT WEST HEALTHCARE)"
                    stackId="a"
                    fill="#FFAC81"
                    stroke="#FFAC81"
                    fillOpacity={0.5}
                    onMouseOver={() =>
                        setPayer(
                            "CIGNA SELECT (FORMERLY GREAT WEST HEALTHCARE)"
                        )
                    }
                    onMouseLeave={onMouseLeave}
                />
                <Bar
                    dataKey="SIERRA HEALTH SERVICES, INC."
                    stackId="a"
                    fill="#F24236"
                    stroke="#F24236"
                    fillOpacity={0.5}
                    onMouseOver={() => setPayer("SIERRA HEALTH SERVICES, INC.")}
                    onMouseLeave={onMouseLeave}
                />
            </BarChart>
        </ResponsiveContainer>
    );
}

PayerRevenueChart.propTypes = {
    payerData: PropTypes.arrayOf(
        PropTypes.shape({
            month: PropTypes.string.isRequired,
            payer: PropTypes.string.isRequired,
            payer_paid: PropTypes.number.isRequired,
        }).isRequired
    ).isRequired,
    chartHeight: PropTypes.number.isRequired,
};

export default PayerRevenueChart;

import React from "react";
import { PropTypes } from "prop-types";
import MuiButton from "@mui/material/Button";

function Button({ onClick, disabled, children, variant }) {
    return (
        <MuiButton variant={variant} onClick={onClick} disabled={disabled}>
            {children}
        </MuiButton>
    );
}

Button.propTypes = {
    onClick: PropTypes.func.isRequired,
    children: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    variant: PropTypes.string,
};

Button.defaultProps = {
    disabled: false,
    variant: "contained",
};

export default Button;

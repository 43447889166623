import { useSelector } from "react-redux";

function useGlobalLoading() {
    const isLoading = useSelector((state) => {
        const isQueryLoading = Object.values(state.api.queries).some(
            (query) => query.status === "pending"
        );

        const isMutationLoading = Object.values(state.api.mutations).some(
            (mutation) => mutation.status === "pending"
        );

        return isQueryLoading || isMutationLoading;
    });
    return isLoading;
}

export default useGlobalLoading;

import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer } from "recharts";
import Figure from "../../Figure";
import CustomTooltip from "./Tooltip";
import { getCurrencyStringFromDollars, getChartData } from "./utils";
import LeftCaretSvg from "../../../assets/LeftCaret.svg";
import RightCaretSvg from "../../../assets/RightCaret.svg";
import styles from "./styles.module.css";

const NUMBER_OF_MONTHS_TO_DISPLAY = 6;

const getFigures = (chartData) => {
    const lastDataPoint = chartData[chartData.length - 1];
    const lastMonth = lastDataPoint.month;
    return (
        <div className={styles.figuresContainer}>
            <div className={styles.figureContainer}>
                <Figure
                    label={`Revenue for ${lastMonth}`}
                    figure={getCurrencyStringFromDollars(lastDataPoint.revenue)}
                    tooltip="The total revenue that you have earned so far this month."
                />
            </div>
            <div className={styles.figureContainer}>
                <Figure
                    label={`Expenses for ${lastMonth}`}
                    figure={getCurrencyStringFromDollars(
                        lastDataPoint.expenses
                    )}
                    tooltip="The total expenses that you have incurred so far this month."
                />
            </div>
        </div>
    );
};

function RevenueAndExpensesChart({
    transactions,
    chartHeight,
    onRevenueBarClick,
    onExpensesBarClick,
}) {
    const chartData = useMemo(() => getChartData(transactions), [transactions]);

    const [index, setIndex] = useState(null);

    useEffect(() => {
        if (chartData.length >= NUMBER_OF_MONTHS_TO_DISPLAY) {
            setIndex(chartData.length - NUMBER_OF_MONTHS_TO_DISPLAY);
        } else if (chartData.length > 0) {
            setIndex(0);
        } else {
            setIndex(null);
        }
    }, [chartData]);

    const onLeftClick = () => {
        setIndex(Math.max(index - 1, 0));
    };

    const onRightClick = () => {
        setIndex(Math.min(index + 1, chartData.length - 1));
    };

    return (
        chartData.length > 0 &&
        index !== null && (
            <>
                {getFigures(chartData)}
                <div className={styles.container}>
                    <div className={styles.caretContainer}>
                        <img
                            onClick={onLeftClick}
                            className={styles.caretIcon}
                            src={LeftCaretSvg}
                            alt="LeftCaret"
                        />
                    </div>
                    <ResponsiveContainer width="100%" height={chartHeight}>
                        <BarChart
                            // If firstIndexShown + NUMBER_OF_MONTHS_TO_DISPLAY >= chartData.length, slice uses chartData.length causing all elements until the end to be extracted.
                            data={chartData.slice(
                                index,
                                index + NUMBER_OF_MONTHS_TO_DISPLAY
                            )}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            barGap={10}
                        >
                            <XAxis dataKey="month" />
                            <Tooltip content={<CustomTooltip />} />
                            <Bar
                                dataKey="expenses"
                                stroke="#59b87d"
                                fill="#59b87d"
                                fillOpacity={0.5}
                                barSize={10}
                                onClick={onExpensesBarClick}
                            />
                            <Bar
                                dataKey="revenue"
                                stroke="#8884d8"
                                fill="#8884d8"
                                fillOpacity={0.3}
                                barSize={10}
                                onClick={onRevenueBarClick}
                            />
                        </BarChart>
                    </ResponsiveContainer>
                    <div className={styles.caretContainer}>
                        <img
                            onClick={onRightClick}
                            className={styles.caretIcon}
                            src={RightCaretSvg}
                            alt="RightCaret"
                        />
                    </div>
                </div>
            </>
        )
    );
}

RevenueAndExpensesChart.propTypes = {
    transactions: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    chartHeight: PropTypes.number.isRequired,
    onRevenueBarClick: PropTypes.func.isRequired,
    onExpensesBarClick: PropTypes.func.isRequired,
};

export default RevenueAndExpensesChart;

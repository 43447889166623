/* eslint-disable camelcase */
import React, { useState } from "react";
import PropTypes from "prop-types";
import Text from "../../../Text";
import Row, { TYPE } from "../Row";
import Payments from "../Payments";
import DownCaretSvg from "../../../../assets/DownCaret.svg";
import rowStyles from "../Row/styles.module.css";
import styles from "./styles.module.css";

export const NUMBER_OF_PAYMENTS_TO_SHOW = 3;

function ExpandablePayments({ dates, amounts }) {
    const [showAllPayments, setShowAllPayments] = useState(false);

    const numberOfPaymentsToShowRange = [
        ...Array(NUMBER_OF_PAYMENTS_TO_SHOW).keys(),
    ];

    const onClick = () => {
        setShowAllPayments(true);
    };

    return showAllPayments ? (
        <Payments amounts={amounts} dates={dates} />
    ) : (
        <div>
            {numberOfPaymentsToShowRange.map((i) => {
                const date = dates[i];
                const amount = amounts[i];
                return (
                    <div key={date}>
                        <Row amount={amount} date={date} type={TYPE.PAYMENT} />
                        <div className={rowStyles.stroke} />
                    </div>
                );
            })}
            <div
                className={styles.showAllRepaymentsContainer}
                onClick={onClick}
            >
                <img
                    className={styles.downCaretIcon}
                    src={DownCaretSvg}
                    alt="DownCaret"
                />
                <Text>
                    <div className={styles.showAllRepayments}>
                        {`Show ${
                            dates.length - NUMBER_OF_PAYMENTS_TO_SHOW
                        } additional weekly repayments`}
                    </div>
                </Text>
            </div>
        </div>
    );
}

ExpandablePayments.propTypes = {
    dates: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    amounts: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
};

export default ExpandablePayments;

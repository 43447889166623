import firebase from "firebase/compat/app";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setIsUserSignedIn } from "../index";
import { apiSlice } from "../../api";

const signOut = createAsyncThunk(
    "authentication/signOut",
    async (arg, thunkApi) => {
        await firebase.auth().signOut();
        thunkApi.dispatch(setIsUserSignedIn(false));
        thunkApi.dispatch(apiSlice.util.resetApiState());
    }
);

export default signOut;

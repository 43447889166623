import React from "react";
import ProviderDetails from "../ProviderDetails";
import Plaid from "../Plaid";
import SelectBankAccount from "../SelectBankAccount";
import Complete from "../Complete";
import Offer from "../Offer";
import Terms from "../Terms";
import STATES from "./states";

export const getState = (provider) => {
    if (!provider) {
        return STATES.USER_CREATED;
    }
    return provider.current_state;
};

export const getContent = (state) => {
    switch (state) {
        case STATES.USER_CREATED:
            return <ProviderDetails />;
        case STATES.PROVIDER_CREATED:
            return <Plaid />;
        case STATES.PLAID_CONNECTED:
            return <SelectBankAccount />;
        case STATES.UNDERWRITING:
            return <Complete />;
        case STATES.OFFER_SENT:
            return <Offer />;
        case STATES.OFFER_REVIEWED:
            return <Terms />;
        default:
            throw new Error("No content for state");
    }
};

import React from "react";
import PropTypes from "prop-types";
import Tabs from "./Tabs";
import Content from "../Content";
import SideBar from "../SideBar";
import { getContent } from "./utils";
import STATES from "./states";

function Onboarding({ state }) {
    return (
        <>
            <SideBar>
                <Tabs state={state} />
            </SideBar>
            <Content>{getContent(state)}</Content>
        </>
    );
}

Onboarding.propTypes = {
    state: PropTypes.oneOf(Object.values(STATES)).isRequired,
};

export default Onboarding;

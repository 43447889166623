/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import Text from "../../Text";
import Table from "../../Table";
import { getCurrencyString, getDateString } from "../../../utils";
import styles from "./styles.module.css";

function PaymentsTable({ payments }) {
    const headers = ["ID", "Collected date", "Amount"];
    const rows = payments.map((payment) => {
        const { id, ach_sent_date, payment_amount } = payment;
        const headerToContent = {
            ID: (
                <Text>
                    <div className={styles.columnContent}>{id}</div>
                </Text>
            ),
            "Collected date": (
                <Text>
                    <div className={styles.columnContent}>
                        {getDateString(new Date(ach_sent_date))}
                    </div>
                </Text>
            ),
            Amount: (
                <Text>
                    <div className={styles.columnContent}>
                        {getCurrencyString(payment_amount)}
                    </div>
                </Text>
            ),
        };
        return {
            id,
            headerToContent,
        };
    });
    return <Table headers={headers} rows={rows} />;
}

PaymentsTable.propTypes = {
    payments: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            ach_sent_date: PropTypes.string.isRequired,
            payment_amount: PropTypes.number.isRequired,
        }).isRequired
    ).isRequired,
};

export default PaymentsTable;

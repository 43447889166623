import React from "react";
import PropTypes from "prop-types";
import Text from "../../../Text";
import { getCurrencyString } from "../../../../utils";
import styles from "./styles.module.css";

const getColor = (payload, payer) =>
    payload.find((bar) => bar.dataKey === payer).color;

function Tooltip({ active, payload, payer }) {
    if (active && payload.length > 0 && payer) {
        const payerRevenue = payload[0].payload[payer];
        const color = getColor(payload, payer);
        const payerStyle = {
            color,
        };

        return (
            <div className={styles.container}>
                <Text>
                    <div style={payerStyle}>{payer}</div>
                </Text>
                <Text>
                    <div className={styles.revenue}>
                        {getCurrencyString(payerRevenue)}
                    </div>
                </Text>
            </div>
        );
    }
    return null;
}

Tooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.arrayOf(
        PropTypes.shape({
            payload: PropTypes.object.isRequired,
        }).isRequired
    ),
    payer: PropTypes.string,
};

Tooltip.defaultProps = {
    active: false,
    payload: [],
    payer: undefined,
};

export default Tooltip;

/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import PlaidInstitution from "../../PlaidInstitution";
import PlaidAccountRadio from "./PlaidAccountRadio";
import styles from "./styles.module.css";

function PlaidAccountsRadioGroup({ plaidItems, onChange, currentValue }) {
    return (
        <>
            {plaidItems.map((plaidItem) => {
                const {
                    item: { institution, item_id },
                    accounts,
                } = plaidItem;
                return (
                    <div key={item_id} className={styles.plaidItemContainer}>
                        <div className={styles.institutionContainer}>
                            <PlaidInstitution
                                institution={institution}
                                numberOfAccounts={accounts.length}
                            />
                        </div>
                        {accounts.map((account) => {
                            const { account_id } = account;
                            return (
                                <div
                                    className={
                                        styles.plaidAccountRadioContainer
                                    }
                                    key={account_id}
                                >
                                    <PlaidAccountRadio
                                        account={account}
                                        onChange={onChange}
                                        currentValue={currentValue}
                                    />
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </>
    );
}

PlaidAccountsRadioGroup.propTypes = {
    plaidItems: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    onChange: PropTypes.func.isRequired,
    currentValue: PropTypes.string.isRequired,
};

export default PlaidAccountsRadioGroup;

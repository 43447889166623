import React from "react";
import Text from "../Text";
import PayerRevenueChart from "./PayerRevenueChart";
import { useGetPayerDataQuery } from "../../redux/slices/api";
import styles from "./styles.module.css";

function Claims() {
    // TODO: we can add a parameter to specify how many months back to go.
    const { data: payerData } = useGetPayerDataQuery();

    return payerData ? (
        <div className={styles.container}>
            <div className={styles.headerContainer}>
                <Text>
                    <div className={styles.header}>Payer revenue</div>
                </Text>
            </div>
            <PayerRevenueChart payerData={payerData} chartHeight={500} />
        </div>
    ) : null;
}

export default Claims;

import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import HelpSvg from "../../themes/assets/Help";
import SupportModal from "../Modal/components/SupportModal";
import Text from "../Text";
import themeStyles from "../../themes/styles";
import LogoSvg from "../../themes/assets/Logo";
import styles from "./styles.module.css";

function SideBar({ children }) {
    const [showSupportModal, setShowSupportModal] = useState(false);

    const openSupportModal = useCallback(() => {
        setShowSupportModal(true);
    }, [setShowSupportModal]);

    const closeSupportModal = useCallback(() => {
        setShowSupportModal(false);
    }, [setShowSupportModal]);

    return (
        <div className={`${styles.container} ${themeStyles.sideBar}`}>
            <div className={styles.logoContainer}>
                <img className={styles.logo} src={LogoSvg} alt="Logo" />
            </div>
            {children}
            <div className={styles.helpContainer} onClick={openSupportModal}>
                <img className={styles.helpIcon} src={HelpSvg} alt="Help" />
                <Text>
                    <div
                        className={`${styles.helpText} ${themeStyles.helpText}`}
                    >
                        Help
                    </div>
                </Text>
            </div>
            {showSupportModal && <SupportModal onClose={closeSupportModal} />}
        </div>
    );
}

SideBar.propTypes = {
    children: PropTypes.node,
};

SideBar.defaultProps = {
    children: undefined,
};

export default SideBar;

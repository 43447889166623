/* eslint-disable camelcase */
import React from "react";
import DrawsTable from "./DrawsTable";
import PaymentsTable from "./PaymentsTable";
import UpcomingPaymentsTable from "./UpcomingPaymentsTable";

const getSections = (financeReport) => {
    const { draws, payments, upcoming_payments } = financeReport;
    const sections = [
        {
            title: "Draws",
            content: <DrawsTable draws={draws} />,
        },
        {
            title: "Payments",
            content: <PaymentsTable payments={payments} />,
        },
        {
            title: "Upcoming payments",
            content: (
                <UpcomingPaymentsTable upcomingPayments={upcoming_payments} />
            ),
        },
    ];
    return sections;
};

export default getSections;

/* eslint-disable camelcase */
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Header, Subheading } from "../Text";
import { useUpdatePlaidItemMutation } from "../../redux/slices/api";
import PlaidLinkButton from "../Plaid/PlaidLinkButton";
import styles from "./styles.module.css";

function UpdatePlaidItem() {
    const { plaid_item_id } = useParams();

    const [updatePlaidItem] = useUpdatePlaidItemMutation();

    const navigate = useNavigate();

    const onSuccess = async () => {
        const body = {
            plaid_item_id,
        };
        try {
            await updatePlaidItem(body).unwrap();
        } catch (error) {
            return;
        }

        navigate(`/plaid_item/${plaid_item_id}/update_success`);
    };

    return (
        <div className={styles.container}>
            <Header>Update connection</Header>
            <Subheading>
                We recently lost connection to your business bank account or
                business credit card. This may happen for a number of reasons
                e.g. if you recently changed your password. Please update the
                connection by clicking the button below.
            </Subheading>
            <div className={styles.updateBankAccountButton}>
                <PlaidLinkButton
                    plaidItemId={plaid_item_id}
                    onSuccess={onSuccess}
                />
            </div>
        </div>
    );
}

export default UpdatePlaidItem;

import React from "react";
import PropTypes from "prop-types";
import { getDateString, getCurrencyString } from "../../../../utils";
import Text from "../../../Text";
import styles from "./styles.module.css";

function Tooltip({ active, payload }) {
    if (active && payload.length > 0) {
        const dataPoint = payload[0].payload;
        return (
            <div className={styles.container}>
                <Text>
                    {getDateString(new Date(dataPoint.created_at_epoch))}
                </Text>
                <Text>{getCurrencyString(dataPoint.bank_balance)}</Text>
            </div>
        );
    }
    return null;
}

Tooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.arrayOf(
        PropTypes.shape({
            payload: PropTypes.shape({
                created_at_epoch: PropTypes.number.isRequired,
                bank_balance: PropTypes.number.isRequired,
            }).isRequired,
        }).isRequired
    ),
};

Tooltip.defaultProps = {
    active: false,
    payload: [],
};

export default Tooltip;

import React from "react";
import { Header, Subheading } from "../../../../components/Text";
import EMAIL from "../../../copy/email";

function Complete() {
    return (
        <>
            <Header>
                Congratulations - you&apos;ve completed the application!
            </Header>
            <Subheading>
                We&apos;ll get back to you within two business days. If you
                don&apos;t hear back from us within two business days, please
                send an email to <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
            </Subheading>
        </>
    );
}

export default Complete;

import React from "react";
import { createHashRouter, RouterProvider } from "react-router-dom";
import ReviewTerms from "../ReviewTerms";
import RouterError from "../RouterError";
import TermsAccepted from "../ReviewTerms/TermsAccepted";
import Overview from "../Overview";
import Cash from "../Cash";
import Claims from "../Claims";
import SideBarAndContent from "./SideBarAndContent";
import UpdatePlaidItem from "../UpdatePlaidItem";
import UpdatePlaidItemSuccess from "../UpdatePlaidItemSuccess";

const router = createHashRouter([
    {
        path: "/",
        element: <SideBarAndContent />,
        errorElement: <RouterError />,
        children: [
            {
                path: "/",
                element: <Overview />,
            },
            {
                path: "/cash",
                element: <Cash />,
            },
            {
                path: "/claims",
                element: <Claims />,
            },
            {
                path: "terms/:drawId",
                element: <ReviewTerms />,
            },
            {
                path: "terms_accepted",
                element: <TermsAccepted />,
            },
            {
                path: "plaid_item/:plaid_item_id/update",
                element: <UpdatePlaidItem />,
            },
            {
                path: "plaid_item/:plaid_item_id/update_success",
                element: <UpdatePlaidItemSuccess />,
            },
        ],
    },
]);

function Dashboard() {
    return <RouterProvider router={router} />;
}

export default Dashboard;

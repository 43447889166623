const STATES = {
    USER_CREATED: "USER_CREATED",
    PROVIDER_CREATED: "PROVIDER_CREATED",
    PLAID_CONNECTED: "PLAID_CONNECTED",
    UNDERWRITING: "UNDERWRITING",
    OFFER_SENT: "OFFER_SENT",
    OFFER_REVIEWED: "OFFER_REVIEWED",
    TERMS_ACCEPTED: "TERMS_ACCEPTED",
};

export default STATES;

/* eslint-disable camelcase */
import React from "react";
import Button from "../Button";
import { Header, Subheading } from "../Text";
import PlaidInstitution from "../PlaidInstitution";
import PlaidAccount from "../PlaidAccount";
import {
    useSavePlaidItemsMutation,
    useGetPlaidItemsQuery,
    useAddPlaidItemMutation,
} from "../../redux/slices/api";
import PlaidLinkButton from "./PlaidLinkButton";
import styles from "./styles.module.css";

function Plaid() {
    const [savePlaidItems] = useSavePlaidItemsMutation();

    const onSaveAndContinue = () => {
        savePlaidItems();
    };

    const { data: plaidItems } = useGetPlaidItemsQuery();

    const [addPlaidItem] = useAddPlaidItemMutation();

    const onSuccess = (publicToken) => {
        const body = {
            public_token: publicToken,
        };
        addPlaidItem(body);
    };

    return (
        <div className={styles.container}>
            <Header>
                Connect all your business bank accounts and business credit
                cards
            </Header>
            <Subheading>
                We use this information to understand the financials of your
                business. If you use multiple business bank accounts or business
                credit cards, please connect them all below.
            </Subheading>
            {plaidItems &&
                plaidItems.map((plaidItem) => {
                    const {
                        item: { institution, item_id },
                        accounts,
                    } = plaidItem;
                    return (
                        <div
                            key={item_id}
                            className={styles.plaidItemContainer}
                        >
                            <div className={styles.plaidInstitutionContainer}>
                                <PlaidInstitution
                                    institution={institution}
                                    numberOfAccounts={accounts.length}
                                />
                            </div>
                            {accounts.map((account) => {
                                const { account_id } = account;
                                return (
                                    <div
                                        className={styles.plaidAccountContainer}
                                        key={account_id}
                                    >
                                        <PlaidAccount account={account} />
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            <div className={styles.connectBankAccountButton}>
                <PlaidLinkButton onSuccess={onSuccess} />
            </div>
            <div className={styles.saveAndContinueButton}>
                <Button
                    onClick={onSaveAndContinue}
                    disabled={!plaidItems || plaidItems.length === 0}
                >
                    Save & Continue
                </Button>
            </div>
        </div>
    );
}

export default Plaid;

/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import Slider from "@mui/material/Slider";
import Figure from "../../Figure";
import { getCurrencyString } from "../../../utils";
import Text from "../../Text";
import styles from "./styles.module.css";

const valueLabelFormat = (value) => getCurrencyString(value);

function Funding({ principal, availableCreditLimit, onChange }) {
    return (
        <div className={styles.container}>
            <div className={styles.headerContainer}>
                <Text>
                    <div className={styles.header}>
                        How much funding would you like to receive?
                    </div>
                </Text>
            </div>
            <div className={styles.figureContainer}>
                <Figure
                    label="Funding amount"
                    figure={getCurrencyString(principal)}
                    tooltip="The total amount of funding that you'll receive."
                />
            </div>
            <Slider
                value={principal}
                onChange={onChange}
                step={10000}
                marks
                min={10000}
                max={availableCreditLimit}
                size="small"
                track="inverted"
                valueLabelFormat={valueLabelFormat}
                valueLabelDisplay="on"
            />
        </div>
    );
}

Funding.propTypes = {
    principal: PropTypes.number.isRequired,
    availableCreditLimit: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default Funding;

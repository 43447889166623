/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import Text from "../../../Text";
import PurpleBulletSvg from "../../../../assets/PurpleBullet.svg";
import GreenBulletSvg from "../../../../assets/GreenBullet.svg";
import { getCurrencyString, getUTCDateString } from "../../../../utils";
import styles from "./styles.module.css";

export const TYPE = {
    FUNDING: "FUNDING",
    PAYMENT: "PAYMENT",
};

function Row({ amount, date, type }) {
    const bulletIconSvg =
        type === TYPE.FUNDING ? GreenBulletSvg : PurpleBulletSvg;

    const amountStyles =
        type === TYPE.FUNDING ? styles.funding : styles.payment;

    const amountString =
        type === TYPE.FUNDING
            ? `${getCurrencyString(amount)} funded to your bank account`
            : `${getCurrencyString(amount)} repayment collected`;

    return (
        <div className={styles.container}>
            <div className={styles.bulletIconAndDateContainer}>
                <img
                    className={styles.bulletIcon}
                    src={bulletIconSvg}
                    alt="Bullet"
                />
                <div className={styles.dateContainer}>
                    <Text>{getUTCDateString(new Date(date))}</Text>
                </div>
            </div>
            <Text>
                <div className={amountStyles}>{amountString}</div>
            </Text>
        </div>
    );
}

Row.propTypes = {
    amount: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

export default Row;

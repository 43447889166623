/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import Text from "../../Text";
import Table from "../../Table";
import { getCurrencyString, getDateString } from "../../../utils";
import styles from "./styles.module.css";

function DrawsTable({ draws }) {
    const headers = ["ID", "Sent date", "Principal", "Fee"];
    const rows = draws.map((draw) => {
        const { id, ach_sent_date, principal, fee } = draw;
        const headerToContent = {
            ID: (
                <Text>
                    <div className={styles.columnContent}>{id}</div>
                </Text>
            ),
            "Sent date": (
                <Text>
                    <div className={styles.columnContent}>
                        {getDateString(new Date(ach_sent_date))}
                    </div>
                </Text>
            ),
            Principal: (
                <Text>
                    <div className={styles.columnContent}>
                        {getCurrencyString(principal)}
                    </div>
                </Text>
            ),
            Fee: (
                <Text>
                    <div className={styles.columnContent}>
                        {getCurrencyString(fee)}
                    </div>
                </Text>
            ),
        };
        return {
            id,
            headerToContent,
        };
    });
    return <Table headers={headers} rows={rows} />;
}

DrawsTable.propTypes = {
    draws: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            ach_sent_date: PropTypes.string.isRequired,
            principal: PropTypes.number.isRequired,
            fee: PropTypes.number.isRequired,
        }).isRequired
    ).isRequired,
};

export default DrawsTable;

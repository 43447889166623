import React from "react";
import PropTypes from "prop-types";
import Text from "../Text";
import styles from "./styles.module.css";

function PlaidInstitution({ institution, numberOfAccounts }) {
    let name;
    let logo;

    if (institution) {
        name = institution.name;
        logo = institution.logo;
    } else {
        name = "Unknown Institution";
        logo = null;
    }

    return (
        <div className={styles.container}>
            {logo && (
                <img
                    className={styles.logo}
                    src={`data:image/png;base64,${logo}`}
                    alt="Instituton Logo"
                />
            )}
            <div className={styles.labelContainer}>
                <Text>
                    <div className={styles.name}>{name}</div>
                </Text>
                <Text>
                    <div className={styles.connections}>
                        {`${numberOfAccounts} connected`}
                    </div>
                </Text>
            </div>
        </div>
    );
}

PlaidInstitution.propTypes = {
    institution: PropTypes.object,
    numberOfAccounts: PropTypes.number.isRequired,
};

PlaidInstitution.defaultProps = {
    institution: null,
};

export default PlaidInstitution;

const data = [
    {
        month: "March 2023",
        "NEVADA MEDICAID": 36428,
        AETNA: 486187,
        "UNITED MEDICAL RESOURCES": 2438,
        "NEVADA BLUE SHIELD": 911366,
        "UNITED HEALTHCARE": 121023,
        "CIGNA SELECT (FORMERLY GREAT WEST HEALTHCARE)": 0,
        "SIERRA HEALTH SERVICES, INC.": 191,
    },
    {
        month: "April 2023",
        "NEVADA MEDICAID": 1525264,
        AETNA: 1216489,
        "UNITED MEDICAL RESOURCES": 2438,
        "NEVADA BLUE SHIELD": 1622470,
        "UNITED HEALTHCARE": 143541,
        "CIGNA SELECT (FORMERLY GREAT WEST HEALTHCARE)": 0,
        "SIERRA HEALTH SERVICES, INC.": 311485,
    },
    {
        month: "May 2023",
        "NEVADA MEDICAID": 5621792,
        AETNA: 1299002,
        "UNITED MEDICAL RESOURCES": 0,
        "NEVADA BLUE SHIELD": 2928003,
        "UNITED HEALTHCARE": 184569,
        "CIGNA SELECT (FORMERLY GREAT WEST HEALTHCARE)": 0,
        "SIERRA HEALTH SERVICES, INC.": 629374,
    },
    {
        month: "June 2023",
        "NEVADA MEDICAID": 15170762,
        AETNA: 2513027,
        "UNITED MEDICAL RESOURCES": 155600,
        "NEVADA BLUE SHIELD": 766047,
        "UNITED HEALTHCARE": 403206,
        "CIGNA SELECT (FORMERLY GREAT WEST HEALTHCARE)": 569300,
        "SIERRA HEALTH SERVICES, INC.": 858987,
    },
    {
        month: "July 2023",
        "NEVADA MEDICAID": 4798743,
        AETNA: 2276064,
        "UNITED MEDICAL RESOURCES": 1351000,
        "NEVADA BLUE SHIELD": 294270,
        "UNITED HEALTHCARE": 689745,
        "CIGNA SELECT (FORMERLY GREAT WEST HEALTHCARE)": 403400,
        "SIERRA HEALTH SERVICES, INC.": 899839,
    },
    {
        month: "August 2023",
        "NEVADA MEDICAID": 5577981,
        AETNA: 1835870,
        "UNITED MEDICAL RESOURCES": 1116300,
        "NEVADA BLUE SHIELD": 1024575,
        "UNITED HEALTHCARE": 989745,
        "CIGNA SELECT (FORMERLY GREAT WEST HEALTHCARE)": 305400,
        "SIERRA HEALTH SERVICES, INC.": 251222,
    },
];

const getChartData = () => data;

export default getChartData;

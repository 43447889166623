import React from "react";
import PropTypes from "prop-types";
import CloseSvg from "../../assets/Close.svg";
import styles from "./styles.module.css";

function Modal({ onClose, children }) {
    return (
        <div className={styles.container}>
            <div className={styles.modal}>
                <div className={styles.children}>{children}</div>
                <div>
                    <div
                        onClick={onClose}
                        className={styles.closeIconContainer}
                    >
                        <img
                            className={styles.closeIcon}
                            src={CloseSvg}
                            alt="Close"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
};

export default Modal;

export const transformGetOrCreateUserResponse = (data) => {
    const { user } = data;
    return user;
};

export const transformGetProviderResponse = (data) => {
    const { provider } = data;
    return provider;
};

/* eslint-disable camelcase */
export const transformGetBankBalancesResponse = (data) => {
    const { bank_balances } = data;

    const bankBalances = bank_balances.map((bankBalance) => {
        const createdAt = new Date(bankBalance.created_at);
        return {
            ...bankBalance,
            created_at_epoch: createdAt.valueOf(),
        };
    });

    return bankBalances;
};

export const transformGetFinanceReportResponse = (data) => {
    const { finance_report } = data;
    return finance_report;
};

export const transformGetBankTransactionsResponse = (data) => {
    const { bank_transactions } = data;

    const bankTransactions = bank_transactions.map((bankTransaction) => {
        const { date } = bankTransaction;
        return {
            ...bankTransaction,
            date_epoch: new Date(date).valueOf(),
        };
    });

    bankTransactions.sort((a, b) => a.date_epoch - b.date_epoch);

    return bankTransactions;
};

export const transformGetPayerDataResponse = (data) => {
    const { payer_data } = data;
    return payer_data;
};

export const transformGetOfferResponse = (data) => {
    const { offer } = data;
    return offer;
};

export const getPlaidLinkTokenUrl = (theme, plaidItemId) => {
    if (!plaidItemId) {
        return `/v1/plaid/link?theme=${theme}`;
    }
    return `/v1/plaid/link?theme=${theme}&plaid_item_id=${plaidItemId}`;
};

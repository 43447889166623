/* eslint-disable camelcase */
import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Button from "../../Button";
import { useLazyGetPlaidLinkTokenQuery } from "../../../redux/slices/api";
import { THEME } from "../../../themes";
import raiseError from "../../../redux/slices/error/thunks/raiseError";

function PlaidLinkButton({ plaidItemId, onSuccess }) {
    const [trigger] = useLazyGetPlaidLinkTokenQuery();

    const plaidHandlerRef = useRef(null);

    const onLinkSuccess = (publicToken) => {
        onSuccess(publicToken);
        plaidHandlerRef.current.destroy();
    };

    const dispatch = useDispatch();

    const onExit = (error, metadata) => {
        if (error) {
            const errObj = {
                error,
                metadata,
            };
            dispatch(raiseError(errObj));
        }
        plaidHandlerRef.current.destroy();
    };

    const onClick = async () => {
        let linkToken;
        try {
            const result = await trigger({
                theme: THEME,
                plaidItemId: plaidItemId || null,
            }).unwrap();
            linkToken = result.link_token;
        } catch (error) {
            // We don't need to raiseException here because middleware will handle exceptions
            return;
        }

        try {
            const config = {
                token: linkToken,
                onSuccess: onLinkSuccess,
                onExit,
            };
            // eslint-disable-next-line no-undef
            const handler = Plaid.create(config);
            plaidHandlerRef.current = handler;
            plaidHandlerRef.current.open();
        } catch (error) {
            dispatch(raiseError(error));
        }
    };

    const buttonText = plaidItemId ? "Update connection" : "Add connection";

    return (
        <Button variant="outlined" onClick={onClick}>
            {buttonText}
        </Button>
    );
}

PlaidLinkButton.propTypes = {
    plaidItemId: PropTypes.string,
    onSuccess: PropTypes.func.isRequired,
};

PlaidLinkButton.defaultProps = {
    plaidItemId: undefined,
};

export default PlaidLinkButton;

import React from "react";
import { Subheading, Header } from "../Text";
import styles from "./styles.module.css";

function Terms() {
    return (
        <div className={styles.container}>
            <Header>Terms</Header>
            <Subheading>
                We&apos;ll be reaching out shortly via email with the terms for
                your digital signature.
            </Subheading>
        </div>
    );
}

export default Terms;

import React, { useMemo } from "react";
import { useGetFinanceReportQuery } from "../../redux/slices/api";
import { getCurrencyString } from "../../utils";
import getSections from "./utils";
import Sections from "./Sections";
import Figure from "../Figure";
import styles from "./styles.module.css";

function Overview() {
    const { data: financeReport } = useGetFinanceReportQuery();

    // TODO: in future, the creditLimit should also be included in financeReport
    const creditLimit = 13000000;

    const sections = useMemo(
        () => financeReport && getSections(financeReport),
        [financeReport]
    );

    return financeReport ? (
        <div className={styles.container}>
            <div className={styles.figuresContainer}>
                <div className={styles.figureContainer}>
                    <Figure
                        label="Balance"
                        figure={getCurrencyString(financeReport.balance)}
                        tooltip="The total amount of credit that we have extended to you so far."
                    />
                </div>
                <div className={styles.figureContainer}>
                    <Figure
                        label="Credit limit"
                        figure={getCurrencyString(creditLimit)}
                        tooltip="The total amount of credit that we are willing to extend to you at any point in time."
                    />
                </div>
            </div>
            <div className={styles.sectionsContainer}>
                <Sections sections={sections} />
            </div>
        </div>
    ) : null;
}

export default Overview;

/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import Figure from "../../Figure";
import { getCurrencyString } from "../../../utils";
import Text from "../../Text";
import PlusSvg from "../../../assets/Plus.svg";
import EqualsSvg from "../../../assets/Equals.svg";
import styles from "./styles.module.css";

const getFeePercentageString = (feePercentage) => {
    const percentage = feePercentage * 100;
    return `${percentage.toFixed(2)}%`;
};

function TotalToRepay({ principal, fee, feePercentage }) {
    const feePercentageString = getFeePercentageString(feePercentage);

    const totalToRepay = principal + fee;

    return (
        <div className={styles.container}>
            <div className={styles.headerContainer}>
                <Text>
                    <div className={styles.header}>
                        The total you will repay is:
                    </div>
                </Text>
            </div>
            <div className={styles.rowContainer}>
                <div className={styles.figureContainer}>
                    <Figure
                        label="Funding amount"
                        figure={getCurrencyString(principal)}
                        tooltip="The total amount of funding that you'll receive."
                    />
                </div>
                <img className={styles.icon} src={PlusSvg} alt="Plus" />
                <div className={styles.figureContainer}>
                    <Figure
                        label={`${feePercentageString} financing fee`}
                        figure={getCurrencyString(fee)}
                        tooltip={`The financing fee is ${feePercentageString} of the funding amount.`}
                    />
                </div>
                <img className={styles.icon} src={EqualsSvg} alt="Equals" />
                <div className={styles.figureContainer}>
                    <Figure
                        label="Total to repay"
                        figure={getCurrencyString(totalToRepay)}
                        tooltip="The total that you will repay."
                    />
                </div>
            </div>
        </div>
    );
}

TotalToRepay.propTypes = {
    principal: PropTypes.number.isRequired,
    fee: PropTypes.number.isRequired,
    feePercentage: PropTypes.number.isRequired,
};

export default TotalToRepay;

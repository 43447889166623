import React from "react";
import PropTypes from "prop-types";
import Text from "../Text";
import styles from "./styles.module.css";

function Table({ headers, rows }) {
    return (
        <table>
            <thead>
                <tr>
                    {headers.map((header) => (
                        <th key={header}>
                            <Text>
                                <div className={styles.header}>{header}</div>
                            </Text>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {rows.map(({ id, headerToContent }) => (
                    <tr key={id}>
                        {headers.map((header) => {
                            const content = headerToContent[header];
                            return <td key={`${header}-${id}`}>{content}</td>;
                        })}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

Table.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            headerToContent: PropTypes.object.isRequired,
        }).isRequired
    ).isRequired,
};

export default Table;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import firebase from "firebase/compat/app";
import {
    transformGetOrCreateUserResponse,
    transformGetProviderResponse,
    transformGetBankBalancesResponse,
    transformGetBankTransactionsResponse,
    transformGetFinanceReportResponse,
    transformGetPayerDataResponse,
    transformGetOfferResponse,
    getPlaidLinkTokenUrl,
} from "./utils";

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: async (headers) => {
        const idToken = await firebase.auth().currentUser.getIdToken();
        headers.set("authorization", `Bearer ${idToken}`);
    },
});

export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery,
    tagTypes: ["provider", "plaidItems"],
    endpoints: (builder) => ({
        getOrCreateUser: builder.query({
            query: () => "/v1/user",
            transformResponse: (data) => transformGetOrCreateUserResponse(data),
        }),
        getProvider: builder.query({
            query: () => "/v1/provider",
            providesTags: ["provider"],
            transformResponse: (data) => transformGetProviderResponse(data),
        }),
        upsertProvider: builder.mutation({
            query: (body) => ({
                url: "/v1/provider",
                method: "POST",
                body,
            }),
            invalidatesTags: ["provider"],
        }),
        getPlaidLinkToken: builder.query({
            query: (args) => {
                const { theme, plaidItemId } = args;
                return getPlaidLinkTokenUrl(theme, plaidItemId);
            },
        }),
        getPlaidItems: builder.query({
            query: () => "/v1/plaid/items",
            providesTags: ["plaidItems"],
        }),
        savePlaidItems: builder.mutation({
            query: () => ({
                url: "/v1/plaid/items",
                method: "POST",
            }),
            invalidatesTags: ["provider"],
        }),
        getPlaidItemsWithOnlyCheckingAccounts: builder.query({
            query: () => "/v1/plaid/items/checking_accounts",
            /*
                getPlaidItemsWithOnlyCheckingAccounts uses a separate endpoint from getPlaidItems so the results returned
                will be stored in separate caches. See the link below for more detail on the behavior of providesTags and
                invalidatesTags and how tags affect cached data:
                https://redux-toolkit.js.org/rtk-query/usage/automated-refetching
            */
            providesTags: ["plaidItems"],
        }),
        getPlaidItem: builder.query({
            query: (plaidItemId) =>
                `/v1/plaid/item?plaid_item_id=${plaidItemId}`,
        }),
        addPlaidItem: builder.mutation({
            query: (body) => ({
                url: "/v1/plaid/link",
                method: "POST",
                body,
            }),
            invalidatesTags: ["plaidItems"],
        }),
        updatePlaidItem: builder.mutation({
            query: (body) => ({
                url: "/v1/plaid/item",
                method: "PUT",
                body,
            }),
        }),
        addProviderBankAccount: builder.mutation({
            query: (body) => ({
                url: "/v1/provider/bank_account",
                method: "POST",
                body,
            }),
            invalidatesTags: ["provider"],
        }),
        getTerms: builder.query({
            query: (drawId) => `/v1/terms/${drawId}`,
        }),
        acceptTerms: builder.mutation({
            query: (body) => ({
                url: "/v1/terms",
                method: "POST",
                body,
            }),
        }),
        getBankBalances: builder.query({
            query: () => `/v1/provider/bank_balances`,
            transformResponse: (data) => transformGetBankBalancesResponse(data),
        }),
        getFinanceReport: builder.query({
            query: () => `/v1/provider/finance_report`,
            transformResponse: (data) =>
                transformGetFinanceReportResponse(data),
        }),
        getBankTransactions: builder.query({
            query: () => `/v1/provider/bank_transactions`,
            transformResponse: (data) =>
                transformGetBankTransactionsResponse(data),
        }),
        getPayerData: builder.query({
            query: () => `/v1/provider/payer_data`,
            transformResponse: (data) => transformGetPayerDataResponse(data),
        }),
        getOffer: builder.query({
            query: () => `/v1/provider/offer`,
            transformResponse: (data) => transformGetOfferResponse(data),
        }),
        updateOffer: builder.mutation({
            query: (body) => ({
                url: "/v1/provider/offer",
                method: "PUT",
                body,
            }),
            invalidatesTags: ["provider"],
        }),
    }),
});

export const {
    useGetOrCreateUserQuery,
    useGetProviderQuery,
    useUpsertProviderMutation,
    useLazyGetPlaidLinkTokenQuery,
    useGetPlaidItemsQuery,
    useSavePlaidItemsMutation,
    useGetPlaidItemsWithOnlyCheckingAccountsQuery,
    useGetPlaidItemQuery,
    useAddPlaidItemMutation,
    useUpdatePlaidItemMutation,
    useAddProviderBankAccountMutation,
    useGetTermsQuery,
    useAcceptTermsMutation,
    useGetBankBalancesQuery,
    useGetFinanceReportQuery,
    useGetBankTransactionsQuery,
    useGetPayerDataQuery,
    useGetOfferQuery,
    useUpdateOfferMutation,
} = apiSlice;

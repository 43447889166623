import React, { useState } from "react";
import PropTypes from "prop-types";
import Text from "../Text";
import InformationSvg from "../../assets/Information.svg";
import styles from "./styles.module.css";

function Figure({ label, tooltip, figure }) {
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = (e) => {
        const { clientX, clientY } = e;
        if (!showTooltip) {
            setTooltipPosition({ x: clientX, y: clientY });
            setShowTooltip(true);
        }
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    const tooltipStyle = {
        position: "absolute",
        zIndex: 9998,
        top: tooltipPosition.y,
        left: tooltipPosition.x,
        backgroundColor: "white",
        border: "1px solid #DCDCD9",
        padding: "4px",
        borderRadius: "4px",
        width: "200px",
    };

    return (
        <div className={styles.container}>
            <div className={styles.figureLabelContainer}>
                <Text>
                    <div className={styles.figureLabel}>{label}</div>
                </Text>
                <img
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    className={styles.icon}
                    src={InformationSvg}
                    alt="Information"
                />
            </div>
            <Text>
                <div className={styles.figure}>{figure}</div>
            </Text>
            {showTooltip && <div style={tooltipStyle}>{tooltip}</div>}
        </div>
    );
}

Figure.propTypes = {
    label: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
    figure: PropTypes.string.isRequired,
};

export default Figure;

import { configureStore } from "@reduxjs/toolkit";
import authenticationReducer from "./slices/authentication";
import errorReducer from "./slices/error";
import { apiSlice } from "./slices/api";
import asyncThunkErrorMonitor from "./middleware/asyncThunkErrorMonitor";

export default configureStore({
    reducer: {
        authentication: authenticationReducer,
        error: errorReducer,
        [apiSlice.reducerPath]: apiSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(apiSlice.middleware)
            .concat(asyncThunkErrorMonitor),
});

/* eslint-disable camelcase */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Text from "../../Text";
import ExpandablePayments, {
    NUMBER_OF_PAYMENTS_TO_SHOW,
} from "./ExpandablePayments";
import Payments from "./Payments";
import Row, { TYPE } from "./Row";
import rowStyles from "./Row/styles.module.css";
import styles from "./styles.module.css";

const getPaymentAmounts = (totalToRepay, numberOfPayments) => {
    const paymentAmount = Math.ceil(totalToRepay / numberOfPayments);

    const lastPaymentAmount =
        totalToRepay - (numberOfPayments - 1) * paymentAmount;

    const numberOfPaymentsMinusOneRange = [
        ...Array(numberOfPayments - 1).keys(),
    ];

    const paymentAmounts = numberOfPaymentsMinusOneRange.map(
        () => paymentAmount
    );

    paymentAmounts.push(lastPaymentAmount);

    return paymentAmounts;
};

function Schedule({ totalToRepay, paymentDates, fundingDate, fundingAmount }) {
    const paymentAmounts = useMemo(
        () => getPaymentAmounts(totalToRepay, paymentDates.length),
        [totalToRepay, paymentDates]
    );

    return (
        <div className={styles.container}>
            <div className={styles.headerContainer}>
                <Text>
                    <div className={styles.header}>
                        Review the schedule of payments:
                    </div>
                </Text>
            </div>
            <div className={styles.rowsContainer}>
                {fundingDate && fundingAmount && (
                    <>
                        <Row
                            amount={fundingAmount}
                            date={fundingDate}
                            type={TYPE.FUNDING}
                        />
                        <div className={rowStyles.stroke} />
                    </>
                )}
                {paymentDates.length > NUMBER_OF_PAYMENTS_TO_SHOW ? (
                    <ExpandablePayments
                        dates={paymentDates}
                        amounts={paymentAmounts}
                    />
                ) : (
                    <Payments dates={paymentDates} amounts={paymentAmounts} />
                )}
            </div>
        </div>
    );
}

Schedule.propTypes = {
    totalToRepay: PropTypes.number.isRequired,
    paymentDates: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    fundingDate: PropTypes.string,
    fundingAmount: PropTypes.number,
};

Schedule.defaultProps = {
    fundingDate: undefined,
    fundingAmount: undefined,
};

export default Schedule;

import { getCurrencyString } from "../../../utils";

const getFirstMonthStart = (transaction) => {
    const earliestDate = new Date(transaction.date);
    return new Date(earliestDate.getFullYear(), earliestDate.getMonth(), 1);
};

const getLastMonthStart = (transaction) => {
    const latestDate = new Date(transaction.date);
    return new Date(latestDate.getFullYear(), latestDate.getMonth(), 1);
};

const getNextMonthStart = (currentMonthStart) => {
    const nextMonthStart = new Date(
        currentMonthStart.getFullYear(),
        currentMonthStart.getMonth() + 1,
        1
    );

    if (nextMonthStart.getMonth() === 0) {
        nextMonthStart.setFullYear(currentMonthStart.getFullYear() + 1);
    }

    return nextMonthStart;
};

const getMonthlyBounds = (transactions) => {
    const firstMonthStart = getFirstMonthStart(transactions[0]);
    const lastMonthStart = getLastMonthStart(
        transactions[transactions.length - 1]
    );

    const monthlyBounds = [];

    let currentMonthStart = firstMonthStart;

    while (currentMonthStart <= lastMonthStart) {
        const nextMonthStart = getNextMonthStart(currentMonthStart);
        monthlyBounds.push([currentMonthStart, nextMonthStart]);
        currentMonthStart = nextMonthStart;
    }

    return monthlyBounds;
};

const getTransactionsInMonth = (transactions, monthStart, nextMonthStart) =>
    transactions.filter((transaction) => {
        const transactionDate = new Date(transaction.date);

        if (transactionDate >= monthStart && transactionDate < nextMonthStart) {
            return true;
        }

        return false;
    });

const getDataPoint = (transactions, monthStart, nextMonthStart) => {
    const transactionInMonth = getTransactionsInMonth(
        transactions,
        monthStart,
        nextMonthStart
    );

    let expenses = 0;
    let revenue = 0;

    transactionInMonth.forEach((transaction) => {
        const { amount } = transaction;
        if (amount > 0) {
            expenses += amount;
        } else {
            revenue += -amount;
        }
    });

    return {
        month: monthStart.toLocaleString("en-US", {
            month: "short",
            year: "numeric",
        }),
        expenses,
        revenue,
    };
};

export const getChartData = (transactions) => {
    if (transactions.length === 0) {
        return [];
    }
    const monthlyBounds = getMonthlyBounds(transactions);
    return monthlyBounds.map(([monthStart, nextMonthStart]) =>
        getDataPoint(transactions, monthStart, nextMonthStart)
    );
};

export const getCurrencyStringFromDollars = (dollars) => {
    const cents = Math.round(dollars * 100);
    return getCurrencyString(cents);
};

import React from "react";
import { useNavigate } from "react-router-dom";
import { Header, Subheading } from "../../Text";
import Button from "../../Button";
import styles from "./styles.module.css";

function TermsAccepted() {
    const navigate = useNavigate();

    const onClick = () => {
        navigate("/");
    };

    // TODO: Do we want to fetch the offer here to show the user the funding date?

    return (
        <div>
            <Header>Thank you for accepting the terms.</Header>
            <Subheading>
                {`You'll receive the funding in your selected bank account on the funding date. We'll send you an email confirmation on the day of funding.`}
            </Subheading>
            <div className={styles.buttonContainer}>
                <Button onClick={onClick}>Go to dashboard</Button>
            </div>
        </div>
    );
}

export default TermsAccepted;

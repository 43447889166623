import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import Text from "../../Text";
import styles from "./styles.module.css";
import themeStyles from "../../../themes/styles";

const isCurrentTab = (tabPath, currentPath) => {
    if (tabPath === "/") {
        if (currentPath === "/") {
            return true;
        }
        return false;
    }

    return currentPath.startsWith(tabPath);
};

function NavigationTab({ path, children, iconSrc }) {
    let containerStyles = styles.container;
    const location = useLocation();

    if (isCurrentTab(path, location.pathname)) {
        containerStyles = `${styles.container} ${themeStyles.currentDashboardTab}`;
    }

    const navigate = useNavigate();

    const onClick = useCallback(() => {
        navigate(path);
    });

    return (
        <div className={containerStyles} onClick={onClick}>
            <img className={styles.icon} src={iconSrc} alt={path} />
            <Text>{children}</Text>
        </div>
    );
}

NavigationTab.propTypes = {
    path: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
    iconSrc: PropTypes.string.isRequired,
};

export default NavigationTab;

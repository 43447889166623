import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";

function Content({ children }) {
    return <div className={styles.container}>{children}</div>;
}

Content.propTypes = {
    children: PropTypes.node,
};

Content.defaultProps = {
    children: undefined,
};

export default Content;

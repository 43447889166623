import React, { useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebaseui/dist/firebaseui.css";
import * as firebaseui from "firebaseui";
import SideBar from "../SideBar";
import Content from "../Content";
import styles from "./styles.module.css";

const uiShown = () => {
    const originalParagraph = document.querySelector(
        "p.firebaseui-tospp-full-message"
    );
    if (originalParagraph) {
        const duplicatedParagraph = originalParagraph.cloneNode(true);
        duplicatedParagraph.textContent =
            "All data collected is treated with the utmost confidentiality and protected in accordance with applicable data protection laws. © 2023 Flychain, Inc. All rights reserved.";
        originalParagraph.parentNode.appendChild(duplicatedParagraph);
    } else {
        console.error("Query selector did not find any element.");
    }
};

function Login() {
    useEffect(() => {
        const uiConfig = {
            callbacks: {
                signInSuccessWithAuthResult: () => false,
                uiShown,
            },
            signInOptions: [
                {
                    provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                    signInMethod:
                        firebase.auth.EmailAuthProvider
                            .EMAIL_PASSWORD_SIGN_IN_METHOD,
                },
            ],
            tosUrl: "https://drive.google.com/file/d/1f9--NwimBefFcc1cpsAMf7V7fXnsmuLl/view?usp=drive_link",
            privacyPolicyUrl:
                "https://drive.google.com/file/d/1S7PITvhnABYhRkRp_aSFYsRN5JPEmQna/view?usp=sharing",
        };

        const ui =
            firebaseui.auth.AuthUI.getInstance() ||
            new firebaseui.auth.AuthUI(firebase.auth());

        ui.start("#firebaseui-auth-container", uiConfig);
    }, []);

    return (
        <>
            <SideBar />
            <Content>
                <div className={styles.firebaseContainer}>
                    <div id="firebaseui-auth-container" />
                </div>
            </Content>
        </>
    );
}

export default Login;

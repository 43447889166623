/* eslint-disable camelcase */
import React from "react";
import { useParams } from "react-router-dom";
import { Header, Subheading } from "../Text";
import PlaidInstitution from "../PlaidInstitution";
import PlaidAccount from "../PlaidAccount";
import { useGetPlaidItemQuery } from "../../redux/slices/api";
import styles from "./styles.module.css";

function UpdatePlaidItemSuccess() {
    const { plaid_item_id } = useParams();

    const { data: plaidItem } = useGetPlaidItemQuery(plaid_item_id);

    return (
        <div className={styles.container}>
            <Header>You successfully updated the connection!</Header>
            <Subheading>
                Thank you for successfully updating the connection (see your
                account details below). There is nothing else that you need to
                do at this time.
            </Subheading>
            {plaidItem && (
                <div className={styles.plaidItemContainer}>
                    <div className={styles.plaidInstitutionContainer}>
                        <PlaidInstitution
                            institution={plaidItem.item.institution}
                            numberOfAccounts={plaidItem.accounts.length}
                        />
                    </div>
                    {plaidItem.accounts.map((account) => {
                        const { account_id } = account;
                        return (
                            <div
                                className={styles.plaidAccountContainer}
                                key={account_id}
                            >
                                <PlaidAccount account={account} />
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default UpdatePlaidItemSuccess;

import React from "react";
import {
    useGetBankBalancesQuery,
    useGetBankTransactionsQuery,
} from "../../redux/slices/api";
import BankBalanceChart from "./BankBalanceChart";
import RevenueAndExpensesChart from "./RevenueAndExpensesChart";
import styles from "./styles.module.css";

function Cash() {
    const { data: bankBalances } = useGetBankBalancesQuery();

    const { data: bankTransactions } = useGetBankTransactionsQuery();

    const onRevenueBarClick = () => {
        console.log("onRevenueBarClick");
    };

    const onExpensesBarClick = () => {
        console.log("onExpensesBarClick");
    };

    return bankBalances && bankTransactions ? (
        <div className={styles.container}>
            <BankBalanceChart bankBalances={bankBalances} chartHeight={300} />
            <div className={styles.revenueAndExpensesChartContainer}>
                <RevenueAndExpensesChart
                    transactions={bankTransactions}
                    onRevenueBarClick={onRevenueBarClick}
                    onExpensesBarClick={onExpensesBarClick}
                    chartHeight={300}
                />
            </div>
        </div>
    ) : null;
}

export default Cash;

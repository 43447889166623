import React from "react";
import PropTypes from "prop-types";
import { getCurrencyString } from "../../../../utils";
import Figure from "../../../Figure";
import styles from "./styles.module.css";

function ChartTitle({ balance }) {
    const balanceString = balance
        ? getCurrencyString(balance)
        : "There is no balance yet";
    return (
        <div className={styles.container}>
            <Figure
                label="Total balance"
                figure={balanceString}
                tooltip="The total balance across all of your connected bank accounts"
            />
        </div>
    );
}

ChartTitle.propTypes = {
    balance: PropTypes.number,
};

ChartTitle.defaultProps = {
    balance: undefined,
};

export default ChartTitle;

import React, { useState, useEffect } from "react";
import Text from "../Text";
import Button from "../Button";
import {
    useUpdateOfferMutation,
    useGetOfferQuery,
} from "../../redux/slices/api";
import Funding from "./Funding";
import TotalToRepay from "./TotalToRepay";
import Schedule from "./Schedule";
import styles from "./styles.module.css";

function Offer() {
    const { data: offer } = useGetOfferQuery();

    const [principal, setPrincipal] = useState(0);
    const [fee, setFee] = useState(0);

    useEffect(() => {
        if (offer) {
            setPrincipal(offer.principal_cents);
            setFee(offer.fee_cents);
        }
    }, [offer, setPrincipal, setFee]);

    const onSliderChange = (_, value) => {
        setPrincipal(value);
        setFee(Math.ceil(offer.fee_percentage * value));
    };

    const [updateOffer] = useUpdateOfferMutation();

    const onConfirmOffer = async () => {
        // For other types of offers, we won't specify principal
        const body = {
            offer_id: offer.id,
            principal,
            confirmed: true,
        };
        updateOffer(body);
    };

    const onSkip = () => {
        const body = {
            offer_id: offer.id,
            confirmed: false,
        };
        updateOffer(body);
    };

    return offer ? (
        <div>
            <Text>
                <div className={styles.header}>
                    We&apos;re excited to present your offer
                </div>
            </Text>
            <div className={styles.sectionDivider} />
            <Funding
                principal={principal}
                availableCreditLimit={offer.available_credit_limit_cents}
                onChange={onSliderChange}
            />
            <div className={styles.sectionDivider} />
            <TotalToRepay
                principal={principal}
                fee={fee}
                feePercentage={offer.fee_percentage}
            />
            <div className={styles.sectionDivider} />
            <Schedule
                totalToRepay={principal + fee}
                paymentDates={offer.payment_dates}
                fundingDate={offer.funding_date}
                fundingAmount={principal}
            />
            <div className={styles.sectionDivider} />
            <div className={styles.buttonsContainer}>
                <div className={styles.buttonContainer}>
                    <Button onClick={onConfirmOffer}>Confirm offer</Button>
                </div>
                <div className={styles.buttonContainer}>
                    <Button onClick={onSkip} variant="outlined">
                        Skip for now
                    </Button>
                </div>
            </div>
        </div>
    ) : null;
}

export default Offer;

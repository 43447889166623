import React from "react";
import PropTypes from "prop-types";
import Text from "../../../Text";
import { getCurrencyStringFromDollars } from "../utils";
import styles from "./styles.module.css";

function Tooltip({ active, payload }) {
    if (active && payload.length > 0) {
        const dataPoint = payload[0].payload;
        const { revenue, expenses } = dataPoint;

        return (
            <div className={styles.container}>
                <Text>
                    <div
                        className={styles.revenueText}
                    >{`Revenue: ${getCurrencyStringFromDollars(revenue)}`}</div>
                </Text>
                <Text>
                    <div
                        className={styles.expensesText}
                    >{`Expenses: ${getCurrencyStringFromDollars(
                        expenses
                    )}`}</div>
                </Text>
            </div>
        );
    }
    return null;
}

Tooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.arrayOf(
        PropTypes.shape({
            payload: PropTypes.shape({
                expenses: PropTypes.number.isRequired,
                revenue: PropTypes.number.isRequired,
            }).isRequired,
        }).isRequired
    ),
};

Tooltip.defaultProps = {
    active: false,
    payload: [],
};

export default Tooltip;

import { isRejected } from "@reduxjs/toolkit";
import raiseError from "../slices/error/thunks/raiseError";

const asyncThunkErrorMonitor = (store) => (next) => (action) => {
    if (isRejected(action)) {
        store.dispatch(raiseError(action.error));
    }
    return next(action);
};

export default asyncThunkErrorMonitor;

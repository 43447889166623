import React from "react";
import { useSelector } from "react-redux";
import Text from "../Text";
import styles from "./styles.module.css";

const GENERAL_ERROR_MESSAGE = "An error occurred. Please refresh the page.";

const INVALID_URL_ERROR_MESSAGE = "Unknown link. Please try another link.";

const isInvalidUrlError = (error) => {
    if (
        error &&
        typeof error === "object" &&
        error.message &&
        typeof error.message === "string" &&
        error.message.includes("No route matches URL")
    ) {
        return true;
    }
    return false;
};

function ErrorFallback() {
    const error = useSelector((state) => state.error);

    return (
        <div className={styles.container}>
            <Text>
                {isInvalidUrlError(error)
                    ? INVALID_URL_ERROR_MESSAGE
                    : GENERAL_ERROR_MESSAGE}
            </Text>
        </div>
    );
}

export default ErrorFallback;

import React from "react";
import { Header, Subheading } from "../../../../components/Text";

function Complete() {
    return (
        <>
            <Header>Thank you!</Header>
            <Subheading>
                We will review your information and send your agreement to sign
                within two business days. If you do not hear back within two
                business days, please contact your CoachCare account manager.
            </Subheading>
        </>
    );
}

export default Complete;

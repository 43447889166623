import React, { useState } from "react";
import PropTypes from "prop-types";
import { Subheading } from "../../Text";
import styles from "./styles.module.css";

function Sections({ sections }) {
    const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
    const titles = sections.map((section) => section.title);
    const content = sections.map((section) => section.content);

    return (
        <div>
            <div className={styles.sectionTitlesContainer}>
                {titles.map((title, i) => {
                    const onClick = () => {
                        setCurrentSectionIndex(i);
                    };

                    let style = `${styles.sectionTitle}`;
                    if (i === currentSectionIndex) {
                        style = `${styles.sectionTitle} ${styles.currentTitle}`;
                    }
                    return (
                        <div key={title} className={style} onClick={onClick}>
                            <Subheading>{title}</Subheading>
                        </div>
                    );
                })}
            </div>
            {content[currentSectionIndex]}
        </div>
    );
}

Sections.propTypes = {
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            content: PropTypes.element.isRequired,
        }).isRequired
    ).isRequired,
};

export default Sections;

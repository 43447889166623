/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import Text from "../../Text";
import Table from "../../Table";
import { getCurrencyString, getUTCDateString } from "../../../utils";
import styles from "./styles.module.css";

function UpcomingPaymentsTable({ upcomingPayments }) {
    const headers = ["ID", "Collection date", "Amount"];
    const rows = upcomingPayments.map((upcomingPayment) => {
        const { id, earliest_payment_date, payment_amount } = upcomingPayment;
        const headerToContent = {
            ID: (
                <Text>
                    <div className={styles.columnContent}>{id}</div>
                </Text>
            ),
            "Collection date": (
                <Text>
                    <div className={styles.columnContent}>
                        {getUTCDateString(new Date(earliest_payment_date))}
                    </div>
                </Text>
            ),
            Amount: (
                <Text>
                    <div className={styles.columnContent}>
                        {getCurrencyString(payment_amount)}
                    </div>
                </Text>
            ),
        };
        return {
            id,
            headerToContent,
        };
    });
    return <Table headers={headers} rows={rows} />;
}

UpcomingPaymentsTable.propTypes = {
    upcomingPayments: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            earliest_payment_date: PropTypes.string.isRequired,
            payment_amount: PropTypes.number.isRequired,
        }).isRequired
    ).isRequired,
};

export default UpcomingPaymentsTable;

import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import signOut from "../../redux/slices/authentication/thunks/signOut";
import { useGetProviderQuery } from "../../redux/slices/api";
import raiseError from "../../redux/slices/error/thunks/raiseError";
import Dashboard from "../Dashboard";
import Onboarding from "../Onboarding";
import { getState } from "../Onboarding/utils";
import STATES from "../Onboarding/states";
import styles from "./styles.module.css";

function Authenticated() {
    const { data: provider } = useGetProviderQuery();

    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(signOut());
    };

    const triggerError = () => {
        const err = new Error("Triggering test error");
        dispatch(raiseError(err));
    };

    const onboardingState = useMemo(() => {
        // provider could be null, which is why we explictly check that provider is not undefined
        if (provider !== undefined) {
            return getState(provider);
        }
        return undefined;
    }, [provider]);

    return onboardingState ? (
        <>
            {onboardingState === STATES.TERMS_ACCEPTED ? (
                <Dashboard />
            ) : (
                <Onboarding state={onboardingState} />
            )}
            <div
                className={styles.logoutDiv}
                onClick={handleLogout}
                aria-hidden="true"
            />
            <div
                className={styles.triggerErrorDiv}
                onClick={triggerError}
                aria-hidden="true"
            />
        </>
    ) : null;
}

export default Authenticated;

import React from "react";
import { useGetOrCreateUserQuery } from "../../redux/slices/api";
import Authenticated from "../Authenticated";

function FirebaseAuthenticated() {
    const { data: user } = useGetOrCreateUserQuery();

    return user ? <Authenticated /> : null;
}

export default FirebaseAuthenticated;

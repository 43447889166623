/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import Row, { TYPE } from "../Row";
import rowStyles from "../Row/styles.module.css";

function Payments({ amounts, dates }) {
    return (
        <div>
            {amounts.map((amount, i) => {
                const date = dates[i];
                return (
                    <div key={date}>
                        <Row amount={amount} date={date} type={TYPE.PAYMENT} />
                        {i < amounts.length - 1 && (
                            <div className={rowStyles.stroke} />
                        )}
                    </div>
                );
            })}
        </div>
    );
}

Payments.propTypes = {
    dates: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    amounts: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
};

export default Payments;

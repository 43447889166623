import React from "react";
import PropTypes from "prop-types";
import {
    AreaChart,
    Area,
    XAxis,
    Label,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import CustomTooltip from "./Tooltip";
import { getDateString } from "../../../utils";
import ChartTitle from "./ChartTitle";

function BankBalanceChart({ bankBalances, chartHeight }) {
    const latestBalance =
        bankBalances.length > 0
            ? bankBalances[bankBalances.length - 1].bank_balance
            : undefined;

    let startDateString;
    let endDateString;
    if (bankBalances.length > 1) {
        startDateString = getDateString(
            new Date(bankBalances[0].created_at_epoch)
        );
        endDateString = getDateString(
            new Date(bankBalances[bankBalances.length - 1].created_at_epoch)
        );
    }

    return (
        <div>
            <ChartTitle balance={latestBalance} />
            {bankBalances.length > 1 && (
                <ResponsiveContainer width="100%" height={chartHeight}>
                    <AreaChart data={bankBalances}>
                        <defs>
                            <linearGradient
                                id="bankBalanceColor"
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="1"
                            >
                                <stop
                                    offset="5%"
                                    stopColor="#8884d8"
                                    stopOpacity={0.8}
                                />
                                <stop
                                    offset="95%"
                                    stopColor="#8884d8"
                                    stopOpacity={0.2}
                                />
                            </linearGradient>
                        </defs>
                        <XAxis
                            dataKey="created_at_epoch"
                            type="number"
                            domain={["dataMin", "dataMax"]}
                            scale="time"
                            tick={false}
                            axisLine={false}
                        >
                            <Label
                                value={startDateString}
                                position="insideBottomLeft"
                            />
                            <Label
                                value={endDateString}
                                position="insideBottomRight"
                            />
                        </XAxis>
                        <Tooltip content={<CustomTooltip />} />
                        <Area
                            type="monotone"
                            dataKey="bank_balance"
                            stroke="#8884d8"
                            fillOpacity={1}
                            fill="url(#bankBalanceColor)"
                            activeDot={{ r: 6 }}
                        />
                    </AreaChart>
                </ResponsiveContainer>
            )}
        </div>
    );
}

BankBalanceChart.propTypes = {
    bankBalances: PropTypes.arrayOf(
        PropTypes.shape({
            bank_balance: PropTypes.number.isRequired,
            created_at_epoch: PropTypes.number.isRequired,
        }).isRequired
    ).isRequired,
    chartHeight: PropTypes.number.isRequired,
};

export default BankBalanceChart;
